export function SubmitButton({ action, label = 'Valider' }) {
  return (
    <div
      onClick={action}
      className="border py-1 px-2 rounded border-white hover:bg-white hover:text-viguierBleu cursor-pointer"
    >
      {label}
    </div>
  );
}
