import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import {
  Document,
  Font,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useContext } from 'react';

import { getGetPSU } from '../functions/images';
import { ficheFields, mapSubtype, mapType } from '../utils/constants';
import { formatDate, stringifyValue } from '../utils/utils';
import { Context } from './Store';

Font.register({
  family: 'Roboto',
  src: '/fonts/Roboto-Regular.ttf',
});

export function GeneratePdf({ fiche, fetchedData, setError }) {
  const [state] = useContext(Context);
  const styles = StyleSheet.create({
    page: {
      paddingTop: 25,
      paddingBottom: 65,
      paddingHorizontal: 25,
      fontFamily: 'Roboto',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    logo: {
      width: '20%',
    },
    section: {
      margin: 10,
      padding: 10,
      marginVertical: 15,
      borderStyle: 'solid',
      borderWidth: 1,
    },
    subtitle: { fontWeight: 'bold', marginBottom: 10 },
    tableRow: {
      margin: 'auto',
      marginVertical: 4,
      flexDirection: 'row',
    },
    tableCol: {
      width: '50%',
      fontSize: 10,
      flexDirection: 'row',
    },
    label: {
      fontWeight: 'extrabold',
      textDecoration: 'underline',
      fontSize: 10,
    },
    value: { display: 'flex', flexDirection: 'row', flex: 3, fontSize: 10 },
    photos: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    photo: { width: '35%', marginBottom: 10 },
    signatures: { height: 90, maxWidth: 220, marginBottom: 4 },
  });

  /** The field matching the subtype of the fiche, if present */
  const subtypeField = ficheFields[fiche.type * 10 + fiche.subtype];

  function PDFDocument() {
    return (
      <Document title={fiche.numFiche}>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image src="/logo-auriac-remso2.png" style={styles.logo} />
            <Text>
              Fiche{' '}
              {`${mapType(fiche.type)} ${
                fiche.typeFicheDemontage
                  ? mapSubtype(fiche.typeFicheDemontage)
                  : fiche.typeFicheRemontage
                  ? mapSubtype(fiche.typeFicheRemontage)
                  : ''
              }`}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>Informations générales</Text>
            <View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.label}>
                    {ficheFields[0].fields.numDevis.label} :{' '}
                  </Text>
                  <Text style={styles.value}>{fiche.numDevis}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>
                    <Text style={styles.label}>
                      {ficheFields[0].fields.numFiche.label} :{' '}
                    </Text>
                    <Text style={styles.value}>{fiche.numFiche}</Text>
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.label}>
                    {ficheFields[0].fields.client.label} :{' '}
                  </Text>
                  <Text style={styles.value}>
                    {
                      fetchedData.clients.find((v) => v.value === fiche.client)
                        ?.label
                    }
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.label}>
                    {ficheFields[0].fields.resp.label} :{' '}
                  </Text>
                  <Text style={styles.value}>
                    {
                      fetchedData.users.find((v) => v.value === fiche.resp)
                        ?.label
                    }
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.label}>
                    {ficheFields[0].fields.contact.label} :{' '}
                  </Text>
                  <Text style={styles.value}>{fiche.contact}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.label}>
                    {ficheFields[0].fields.telContact.label} :{' '}
                  </Text>
                  <Text style={styles.value}>{fiche.telContact}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.label}>
                    {ficheFields[0].fields.dateDebut.label} :{' '}
                  </Text>
                  <Text style={styles.value}>
                    {formatDate(fiche.dateDebut, true)}
                  </Text>
                </View>
                <View style={styles.tableCol} />
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>{ficheFields[fiche.type].title}</Text>
            <View>
              {Object.keys(ficheFields[fiche.type].fields).map((f, i) => {
                if (
                  !(
                    !fiche.hasOwnProperty(f) ||
                    fiche[f] === null ||
                    (Array.isArray(fiche[f]) && fiche[f].length === 0) ||
                    fiche[f] === '' ||
                    ficheFields[fiche.type].fields[f]?.type === 'image' ||
                    f === 'refRoulementAvant' ||
                    f === 'refRoulementArriere'
                  )
                ) {
                  if (f.includes('typeRoulementAvant')) {
                    var typeAv = fiche[f];
                    var refAv = fiche['refRoulementAvant'];
                    return (
                      <View style={styles.tableRow} key={i}>
                        <View style={{ ...styles.tableCol, width: '100%' }}>
                          <Text style={styles.label}>Roulements Avant:</Text>
                          <Text style={styles.value}>
                            {typeAv.map((type, index) => {
                              return ` type: ${type} - ref: ${refAv[index]},`;
                            })}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                  if (f.includes('typeRoulementArriere')) {
                    var typeAr = fiche[f];
                    var refAr = fiche['refRoulementArriere'];
                    return (
                      <View style={styles.tableRow} key={i}>
                        <View style={{ ...styles.tableCol, width: '100%' }}>
                          <Text style={styles.label}>Roulements Arrières:</Text>
                          <Text style={styles.value}>
                            {typeAr.map((type, index) => {
                              return ` type: ${type} - ref: ${refAr[index]},`;
                            })}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                  return (
                    <View style={styles.tableRow} key={i}>
                      <View style={{ ...styles.tableCol, width: '100%' }}>
                        <Text style={styles.label}>
                          {`${ficheFields[fiche.type].fields[f].label} : `}
                        </Text>
                        <Text style={styles.value}>
                          {stringifyValue(
                            fiche,
                            fetchedData,
                            ficheFields[fiche.type].fields[f],
                            f
                          )}
                          {` ${ficheFields[fiche.type].fields[f]?.unit ?? ''}`}
                        </Text>
                      </View>
                    </View>
                  );
                }
                return <View key={i} />;
              })}
            </View>
          </View>
          {(fiche.type === 2 || fiche.type === 3) && subtypeField && (
            <View style={styles.section}>
              <Text style={styles.subtitle}>{subtypeField.title}</Text>
              <View>
                {Object.keys(subtypeField?.fields).map((f, i) => {
                  if (
                    !(
                      !fiche.hasOwnProperty(f) ||
                      fiche[f] === null ||
                      (Array.isArray(fiche[f]) && fiche[f].length === 0) ||
                      fiche[f] === ''
                    )
                  ) {
                    return (
                      <View style={styles.tableRow} key={i}>
                        <View style={{ ...styles.tableCol, width: '100%' }}>
                          <Text
                            style={styles.label}
                          >{`${subtypeField.fields[f].label} : `}</Text>
                          <Text style={styles.value}>
                            {stringifyValue(
                              fiche,
                              fetchedData,
                              subtypeField.fields[f],
                              f
                            )}
                            {` ${subtypeField.fields[f]?.unit ?? ''}`}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                  return <View key={i} />;
                })}
              </View>
            </View>
          )}
          {fiche.hasOwnProperty('observations') && fiche.observations !== '' && (
            <View style={styles.section} wrap={false}>
              <Text style={styles.subtitle}>Observations</Text>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '100%' }}>
                  <Text style={styles.value}>{fiche.observations}</Text>
                </View>
              </View>
            </View>
          )}
          {fiche.type === 1 && (
            <View style={styles.section} wrap={false}>
              <Text style={styles.subtitle}>Signatures</Text>
              <View style={styles.photos}>
                {fiche.signatureTech && (
                  <View>
                    <Image
                      src={async () =>
                        (await getGetPSU(fiche.signatureTech, state.token))?.url
                      }
                      style={styles.signatures}
                    />
                    <Text style={styles.label}>Signature du technicien</Text>
                  </View>
                )}
                {fiche.signatureClient && (
                  <View>
                    <Image
                      src={async () =>
                        (await getGetPSU(fiche.signatureClient, state.token))
                          ?.url
                      }
                      style={styles.signatures}
                    />
                    <Text style={styles.label}>Signature du client</Text>
                  </View>
                )}
              </View>
            </View>
          )}
          {fiche.photos && fiche.photos.length > 0 && (
            <View style={styles.section} break>
              <Text style={styles.subtitle}>Photos annexes</Text>
              <View style={styles.photos}>
                {fiche.photos.map((id) => {
                  return (
                    <Image
                      src={async () => (await getGetPSU(id, state.token))?.url}
                      style={styles.photo}
                      key={id}
                    />
                  );
                })}
              </View>
            </View>
          )}
        </Page>
      </Document>
    );
  }

  return (
    <PDFDownloadLink
      document={<PDFDocument />}
      fileName={fiche.numFiche}
      className="flex items-center justify-center border py-1 px-2 rounded border-white hover:bg-white hover:text-viguierBleu cursor-pointer"
    >
      {({ error, loading }) => {
        if (loading) {
          return <div className="cursor-default">Génération du PDF...</div>;
        }
        if (error) {
          console.error(error);
          setError('Erreur lors de la génération du PDF');
          return 'Erreur lors de la génération du PDF';
        }
        return (
          <>
            <div className="mr-2">Exporter les données au format PDF</div>
            <PictureAsPdf />
          </>
        );
      }}
    </PDFDownloadLink>
  );
}
