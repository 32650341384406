import TableChart from '@material-ui/icons/TableChart';
import { CSVLink } from 'react-csv';

/**
 * Displays the button to extract pointages to a CSV file
 * @param {{pointages : Array, user: Object, dateMin: String, dateMax: String}} props
 */
export function GenerateCsvPointages({ pointages, user, dateMin, dateMax }) {
  /** @example "PRENOM_NOM_2022-01-01_2022-01-31.csv" */
  const filename = `${user.prenom?.toUpperCase()}_${user.nom?.toUpperCase()}_${dateMin.substring(
    0,
    10
  )}_${dateMax.substring(0, 10)}.csv`;

  /** The maximum of pointages in a day, in the selected period */
  let maxPointagesNumber = 0;

  /** The sum of all days */
  let totalOfPeriod = 0;

  /**
   * Formats pointages to fit with react-csv
   * @type {Array<Array<String>>}
   */
  const formattedPointages = [];
  Object.keys(pointages).forEach((p) => {
    formattedPointages.push([
      p,
      pointages[p].total,
      ...pointages[p].pointages.map((p) =>
        new Date(p.timestamp).toLocaleTimeString().substring(0, 5)
      ),
    ]);
    if (pointages[p].pointages.length > maxPointagesNumber)
      maxPointagesNumber = pointages[p].pointages.length;
    totalOfPeriod += pointages[p].total;
  });

  /** The number of "Début"/"Fin" labels depends on `maxPointagesNumber` */
  const headers = ['Date', 'Total'];
  for (let i = 0; i < maxPointagesNumber; i++) {
    if (i % 2 === 0) headers.push('Début');
    else headers.push('Fin');
  }

  /** The total of month is added at the last row */
  formattedPointages.push([]);
  formattedPointages.push(['Total du mois', totalOfPeriod]);

  return (
    <CSVLink
      data={formattedPointages}
      headers={headers}
      filename={filename}
      tag="_blank"
      className="flex items-center justify-center border py-1 px-2 rounded border-white hover:bg-white hover:text-viguierBleu cursor-pointer"
    >
      <div className="mr-2">Exporter les données au format CSV</div>
      <TableChart />
    </CSVLink>
  );
}
