import AddIcon from '@material-ui/icons/AddCircleRounded';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useContext, useEffect, useState } from 'react';

import {
  deleteImage,
  getGetPSU,
  getPutPSU,
  putMinio,
} from '../functions/images';
import compressImage from '../utils/compress';
import { Context } from './Store';

export function TextFormField({
  label = 'Label',
  unit = '',
  value,
  setter,
  id,
  placeholder = label,
  type = 'text',
  form = '',
  required = false,
  readonly = false,
  options = [],
  numFiche = '',
  setError = () => {},
  setInfo = () => {},
  ...rest
}) {
  const [state] = useContext(Context);
  const [psu, setPsu] = useState('');

  useEffect(() => {
    (async () => {
      if (type === 'image' && value !== '') {
        setPsu((await getGetPSU(value, state.token)).url);
      }
    })();
  }, [state.token, type, value]);

  return (
    <div className="flex flex-col text-left px-2 mb-4">
      <label htmlFor={id}>
        {`${label}`}
        {unit && <span className="italic"> ({unit})</span>}
        {required ? <span className="text-red-600"> *</span> : ''}
      </label>
      {type === 'textarea' ? (
        <textarea
          {...rest}
          rows="4"
          id={id}
          value={value}
          onChange={(e) => setter(e.target.value)}
          placeholder={`${placeholder}...`}
          title={placeholder}
          form={form}
          required={required}
          readOnly={readonly}
          className={`w-full border-2 border-remsoSecond rounded pl-2 py-1 my-1 focus:outline-none focus:ring-2 focus:ring-remsoSecond focus:border-gray-800 text-black h-full ${
            readonly ? 'bg-gray-400 cursor-not-allowed' : ''
          }`}
        />
      ) : type === 'radio' ? (
        <div
          className="flex w-full items-center justify-evenly h-full"
          onChange={(e) => setter(e.target.value)}
        >
          <span>
            <label className="mr-3" htmlFor={`${id}-true`}>{`${
              options.length > 1 ? options[0]?.label : 'Oui'
            }`}</label>
            <input
              {...rest}
              type="radio"
              id={`${id}-true`}
              value={options.length > 1 ? options[0]?.value : 'true'}
              defaultChecked={
                options.length > 1
                  ? options[0]?.value === value
                  : value === true
              }
              name={id}
            />
          </span>
          <span>
            <label className="mr-3" htmlFor={`${id}-false`}>{`${
              options.length > 1 ? options[1]?.label : 'Non'
            }`}</label>
            <input
              {...rest}
              type="radio"
              id={`${id}-false`}
              value={options.length > 1 ? options[1]?.value : 'false'}
              defaultChecked={
                options.length > 1
                  ? options[1]?.value === value
                  : value === false
              }
              name={id}
            />
          </span>
        </div>
      ) : type === 'image' ? (
        <div>
          <div className="flex justify-center items-center mt-1 w-full border-2 border-remsoSecond rounded overflow-ellipsis overflow-x-hidden text-sm bg-white">
            {!psu ? (
              <>
                <input
                  {...rest}
                  id={id}
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (!file || !file.type.startsWith('image/')) {
                      return setError('Le fichier doit être une image valide');
                    }
                    setError('');
                    setInfo("Compression de l'image en cours...");
                    try {
                      const compressedFile = await compressImage(file);
                      setInfo("Téléchargement de l'image en cours...");
                      const psu = await getPutPSU(
                        state.token,
                        `${numFiche}_${id}.${file.type.split('/')[1]}`
                      );
                      if (psu.error) {
                        setInfo('');
                        return setError(`Erreur : ${psu.error}`);
                      }
                      const upload = await putMinio(
                        psu.url,
                        compressedFile,
                        file.type
                      );
                      if (upload.error) {
                        setInfo('');
                        return setError(`Erreur : ${upload.error}`);
                      }
                      setInfo('Image téléchargée avec succès !');
                      setter(psu.name);
                    } catch (error) {
                      console.error(error);
                      setInfo('');
                      return setError(`Impossible d'utiliser la photo`);
                    }
                  }}
                  title={placeholder}
                  form={form}
                  required={required}
                  className="w-full pl-2 py-1 focus:outline-none focus:ring-2 focus:ring-remsoSecond focus:border-gray-800 text-black"
                />
              </>
            ) : (
              <div
                className="text-black cursor-pointer py-1 font-normal"
                onClick={async () => {
                  if (window.confirm("Confirmez la suppression de l'image")) {
                    const deleted = await deleteImage(value, state.token);
                    if (deleted.error) return setError(deleted.error);
                    setInfo("L'image a bien été supprimée");
                    setPsu(null);
                    return setter(null);
                  }
                }}
              >
                Supprimer
                <DeleteIcon />
              </div>
            )}
          </div>
          {psu && value && (
            <img
              src={psu}
              alt="URL manquante ou erronée"
              className="italic font-thin max-h-80 object-contain my-2"
            />
          )}
        </div>
      ) : (
        <input
          {...rest}
          type={type}
          id={id}
          value={value}
          onChange={(e) => setter(e.target.value)}
          placeholder={placeholder}
          title={placeholder}
          form={form}
          required={required}
          readOnly={readonly}
          className={`w-full border-2 border-remsoSecond rounded pl-2 py-1 my-1 focus:outline-none focus:ring-2 focus:ring-remsoSecond focus:border-gray-800 text-black ${
            readonly ? 'bg-gray-400 cursor-not-allowed' : ''
          }`}
        />
      )}
    </div>
  );
}

export function Select({
  id,
  label,
  value,
  setter,
  multiple = false,
  locked = 0,
  options = [],
  required = false,
  inline = false,
}) {
  const clicked = (val, isChecked) => {
    if (isChecked) return setter(value.filter((v) => v !== val));
    return setter([...value, val]);
  };
  return (
    <div
      className={`flex flex-col text-left px-2 max-h-40 ${!inline && 'mb-4'}`}
    >
      <label htmlFor={id}>
        {label}
        {required ? <span className="text-red-600"> *</span> : ''}
      </label>
      {multiple ? (
        <ul className="overflow-auto text-black font-normal bg-white border-2 border-remsoSecond rounded pl-2 py-1 my-1">
          {options.map((o) => {
            const isChecked =
              value.includes(o.value) ||
              value.some((v) => v?.value === o.value);
            return (
              <li key={o.value}>
                {o.value === locked ? (
                  <label className="text-remsoSecond">
                    <CheckBoxIcon />
                    {o.label}
                  </label>
                ) : (
                  <label
                    htmlFor={o.value}
                    className="cursor-pointer"
                    onClick={() => clicked(o.value, isChecked)}
                  >
                    {isChecked ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                    {o.label}
                  </label>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <select
          id={id}
          className={`w-full bg-white border-2 border-remsoSecond rounded ${
            !inline && 'py-1 my-1 pl-2'
          } focus:outline-none focus:ring-2 focus:ring-remsoSecond focus:border-gray-800 text-black`}
          value={value}
          onChange={(e) => setter(e.target.value || null)}
        >
          {options.map((o) => {
            return (
              <option value={o.value} key={o.value}>
                {o.label}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
}

export function ArrayInput({
  id,
  label,
  values = [],
  setter,
  format = {},
  required = false,
  type = 'text',
  options = [],
  numFiche = '',
  setError = () => {},
  setInfo = () => {},
}) {
  const isSimple = Object.keys(format).length <= 0;
  const [newTextValue, setNewTextValue] = useState(
    isSimple ? [''] : Array(Object.keys(format).length).fill('')
  );
  const [state] = useContext(Context);

  const addRow = async () => {
    if (isSimple) {
      if (newTextValue[0] === '') return null;
      setter([...values, newTextValue[0]]);
      setNewTextValue(['']);
    } else {
      if (newTextValue.some((e) => e === '')) return null;
      const newObj = {};
      Object.keys(format).map((f, i) => (newObj[f] = newTextValue[i]));
      setter([...values, newObj]);
      setNewTextValue(Array(Object.keys(format).length).fill(''));
    }
  };

  const deleteRow = async (i) => {
    if (type === 'image') {
      if (window.confirm("Confirmez la suppression de l'image")) {
        const deleted = await deleteImage(i, state.token);
        if (deleted.error) return setError(deleted.error);
        setInfo("L'image a bien été supprimée");
        return setter(values.filter((v2) => i !== v2));
      }
      return;
    }
    setter(values.filter((_, i2) => i !== i2));
  };

  const updateRow = (val, index, field = '') => {
    if (isSimple) {
      setter(
        values.map((f, i2) => {
          if (i2 === index) return val;
          return f;
        })
      );
    } else {
      setter(
        values.map((f, i2) => {
          if (i2 === index) {
            return { ...f, [field]: val };
          }
          return f;
        })
      );
    }
  };

  return (
    <div
      className={`flex flex-col text-left px-2 max-h-40 mb-4 ${
        isSimple ? '' : 'col-span-1 sm:col-span-2 xl:col-span-3 2xl:col-span-4'
      }`}
    >
      <label htmlFor={id}>
        {label}
        {required ? <span className="text-red-600"> *</span> : ''}
      </label>
      <div className="overflow-auto">
        <table className="w-full text-black font-normal bg-white border-1 border-remsoSecond rounded-tr-none rounded py-1 my-1">
          <thead
            className="border-remsoSecond sticky top-0 bg-white"
            style={{ boxShadow: 'inset 0px -15px 10px -15px gray' }}
          >
            {!isSimple ? (
              <tr>
                {Object.keys(format).map((field, i) => {
                  return (
                    <th key={i} className="text-base italic font-normal pl-1">
                      {format[field].label}
                    </th>
                  );
                })}
                <th className="w-10" />
              </tr>
            ) : (
              <tr />
            )}
          </thead>
          <tbody>
            {values.map((v, i) => {
              return (
                <tr key={i}>
                  {!isSimple ? (
                    <>
                      {Object.keys(format).map((field, i2) => {
                        return (
                          <td key={i2}>
                            <textarea
                              type={format[field].type || 'text'}
                              className="w-full border rounded resize-y text-sm py-1 px-1 focus:ring-0"
                              defaultValue={v[field]}
                              onChange={(e) =>
                                updateRow(e.target.value, i, field)
                              }
                            />
                          </td>
                        );
                      })}
                      <td className="flex justify-center items-center">
                        <div
                          className="cursor-pointer"
                          onClick={() => deleteRow(i)}
                        >
                          <DeleteIcon />
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td key={i} className="border-b">
                        <div>
                          {type === 'select' ? (
                            <div className="w-full overflow-ellipsis overflow-x-hidden text-sm py-1 px-2">
                              {v}
                            </div>
                          ) : (
                            <input
                              className="w-full overflow-ellipsis overflow-x-hidden text-sm py-1 px-2 focus:ring-0"
                              value={v}
                              readOnly={type === 'image' ? true : false}
                              onChange={(e) => updateRow(e.target.value, i)}
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="border-l-2 border-remsoSecond flex justify-around">
                          {type === 'image' && (
                            <div
                              className="cursor-pointer"
                              onClick={async () => {
                                const psu = await getGetPSU(v, state.token);
                                if (psu.error) return setError(psu.error);
                                window.open(psu.url, '_blank');
                              }}
                            >
                              <VisibilityIcon />
                            </div>
                          )}
                          <div
                            className="cursor-pointer"
                            onClick={() => deleteRow(type === 'image' ? v : i)}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {!isSimple ? (
              <tr>
                {Object.keys(format).map((field, i) => {
                  return (
                    <td key={i}>
                      <input
                        type={format[field].type || 'text'}
                        className="w-full overflow-ellipsis overflow-x-hidden text-sm py-1 px-1"
                        placeholder="Ajouter un champ..."
                        value={newTextValue[i]}
                        onChange={(e) =>
                          setNewTextValue(
                            newTextValue.map((t, i2) => {
                              return i2 === i ? e.target.value : t;
                            })
                          )
                        }
                      />
                    </td>
                  );
                })}
                <td>
                  <div className="flex border-l-2 border-remsoSecond justify-center items-center">
                    <AddIcon className="cursor-pointer" onClick={addRow} />
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={type !== 'image' ? 1 : 2}>
                  <input
                    className="w-full overflow-ellipsis overflow-x-hidden text-sm py-1 px-2"
                    placeholder={'Ajouter un champ...'}
                    type={type === 'image' ? 'file' : 'text'}
                    value={newTextValue[0]}
                    onChange={async (e) => {
                      if (type === 'image') {
                        const file = e.target.files[0];
                        if (!file || !file.type.startsWith('image/')) {
                          return setError(
                            'Le fichier doit être une image valide'
                          );
                        }
                        setError('');
                        setInfo("Compression de l'image en cours...");
                        try {
                          const compressedFile = await compressImage(file);
                          setInfo("Téléchargement de l'image en cours...");
                          const psu = await getPutPSU(
                            state.token,
                            `${numFiche}_${file.name}`
                          );
                          if (psu.error) {
                            setInfo('');
                            return setError(`Erreur : ${psu.error}`);
                          }
                          const upload = await putMinio(
                            psu.url,
                            compressedFile,
                            file.type
                          );
                          if (upload.error) {
                            setInfo('');
                            return setError(`Erreur : ${upload.error}`);
                          }
                          setInfo('Image téléchargée avec succès !');
                          setter([...values, psu.name]);
                        } catch (error) {
                          console.error(error);
                          setInfo('');
                          return setError(`Impossible d'utiliser la photo`);
                        }
                      } else {
                        setNewTextValue([e.target.value]);
                      }
                    }}
                    list={`datalist-array-${id}`}
                  />
                  {options && (
                    <datalist id={`datalist-array-${id}`}>
                      {options.map((o) => (
                        <option value={o.label} key={o.value} />
                      ))}
                    </datalist>
                  )}
                </td>
                {type !== 'image' && (
                  <td>
                    <div className="border-l-2 border-remsoSecond w-full flex justify-center items-center">
                      <AddIcon className="cursor-pointer" onClick={addRow} />
                    </div>
                  </td>
                )}
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </div>
  );
}
