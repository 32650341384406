import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Message from '../components/Message';
import { Context } from '../components/Store';
import { getPointages } from '../functions/pointages';
import { getUsers } from '../functions/user';
import { TIMEZONE_OFFSET } from '../utils/constants';

export default function Pointages() {
  const [state] = useContext(Context);
  const [users, setUsers] = useState([]);
  const [minDate, setMinDate] = useState(() => {
    const date = new Date();
    date.setHours(4 - TIMEZONE_OFFSET, 0, 0, 0);
    return date.toISOString().substring(0, 16);
  });
  const [maxDate, setMaxDate] = useState(() => {
    const date = new Date();
    date.setHours(date.getHours() - TIMEZONE_OFFSET);
    return date.toISOString().substring(0, 16);
  });

  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetch users list and pointages for a time period
   */
  const fetchData = useCallback(async () => {
    setError(false);
    if (minDate === '') return setError('Veuillez indiquer une date minimum');
    if (maxDate === '') return setError('Veuillez indiquer une date maximum');
    setIsLoading(true);
    // Fetching users list
    const fetchedUsers = await getUsers(state.token, {});
    if (fetchedUsers.error) return setError(fetchedUsers.error);
    // Filtering (only technicians) and sorting users by nom
    const rawUsers =
      fetchedUsers?.data
        .filter((u) => u.status === 1)
        .sort((a, b) =>
          a.nom.toUpperCase().localeCompare(b.nom.toUpperCase())
        ) || [];
    // Setting empty pointages array for each user
    rawUsers.forEach((u) => (u.pointages = []));
    // Fetching pointages between the dates
    const pointages = await getPointages(
      state.token,
      null,
      new Date(minDate).toISOString(),
      new Date(maxDate).toISOString()
    );
    if (pointages.error) {
      setIsLoading(false);
      return setError(pointages.error);
    }
    // Filling pointage array if there are some pointages for this user
    pointages?.data.forEach((pointage) => {
      const userIndex = rawUsers.findIndex((u) => u._id === pointage.user._id);
      if (userIndex > -1) rawUsers[userIndex].pointages.push(pointage);
    });
    setIsLoading(false);
    return setUsers(rawUsers);
  }, [minDate, maxDate, state.token]);

  /**
   * Returns the maximum number of pointages of all users
   * @param {users} users An array of the users with pointages fields
   */
  const getMaxPointages = (users) => {
    return Math.max(...users.map((u) => u.pointages?.length || 0));
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  document.title = 'Pointages';

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12">
        <h1 className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8">
          Pointages
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}

        <div className="bg-viguierBleu rounded">
          <div className="flex items-center justify-between py-2 font-bold bg-viguierBleu text-white rounded-t">
            <div className="flex mx-3">
              <button
                title="Actualiser les pointages"
                onClick={fetchData}
                className="rounded hover:text-remsoMain hover:viguierBleuClair"
              >
                <RefreshIcon />
              </button>
            </div>
            <div className="flex flex-wrap justify-end md:justify-center items-center">
              <div className="flex flex-wrap justify-end items-center">
                <span className="mr-2">Entre le</span>
                <input
                  type="datetime-local"
                  value={minDate}
                  onChange={(e) => setMinDate(e.target.value)}
                  className="border-2 border-remsoSecond rounded pl-2 py-1 my-1 mr-2 focus:outline-none focus:ring-2 focus:ring-remsoSecond focus:border-gray-800 text-black"
                />
              </div>
              <div className="flex flex-wrap justify-end items-center">
                <span className="mr-2">et le</span>
                <input
                  type="datetime-local"
                  value={maxDate}
                  onChange={(e) => setMaxDate(e.target.value)}
                  className="border-2 border-remsoSecond rounded pl-2 py-1 my-1 mr-2 focus:outline-none focus:ring-2 focus:ring-remsoSecond focus:border-gray-800 text-black"
                />
              </div>
            </div>
          </div>
          <div className="border-4 border-white bg-viguierBleuClair mt-5 mb-5 ml-5 mr-5 rounded-md">
            <table className="w-11/12 mb-4 ml-2 sm:ml-12 bg-viguierGrisClair">
              <thead className="bg-viguierBleuClair text-white h-10 ">
                <tr>
                  <th className="w-1/12">Options</th>
                  <th className="w-1/4 lg:w-1/6">Technicien</th>
                  {users && <th colSpan={getMaxPointages(users)} />}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="9">
                      <Message message="Chargement..." type="success" />
                    </td>
                  </tr>
                ) : (
                  users.map((user, i) => {
                    return (
                      <tr
                        key={user._id}
                        className={`group hover:bg-viguierBleu hover:text-white ${
                          i % 2 === 0 && 'bg-gray-400'
                        }`}
                      >
                        <td>
                          <Link
                            to={`/pointages/${user._id}`}
                            className="hover:text-viguierBleuClair"
                          >
                            <InfoIcon />
                          </Link>
                        </td>
                        <td>{`${user.prenom} ${user.nom}`}</td>
                        {/* We iterate through an array of N (max number of pointages of all users in a period) */}
                        {new Array(getMaxPointages(users))
                          .fill()
                          .map((_, p) => {
                            if (user.pointages?.length > p) {
                              /** The timestamp formatted in HH:mm */
                              const hour = new Date(user.pointages[p].timestamp)
                                .toLocaleTimeString()
                                .substring(0, 5);
                              /** The timestamp formatted in DD/MM */
                              const date = new Date(user.pointages[p].timestamp)
                                .toLocaleDateString()
                                .substring(0, 5);
                              return (
                                // If the pointage is a start time, displayed in green. If it is an end time, displayed in red
                                <td
                                  key={p}
                                  className={`group-hover:text-black ${
                                    p % 2 === 0
                                      ? 'bg-green-400 group-hover:bg-green-500'
                                      : 'bg-red-400 group-hover:bg-red-500'
                                  }`}
                                >
                                  <span className="italic">
                                    {`${
                                      // If the date of the pointage is different than today, we display the date
                                      new Date()
                                        .toLocaleDateString()
                                        .substring(0, 5) !== date
                                        ? `(${date}) `
                                        : ''
                                    }`}
                                  </span>
                                  <span className="font-bold">{hour}</span>
                                </td>
                              );
                            }
                            return <td key={p} />;
                          })}
                        {/* If there is no pointage on the period, display blank lines */}
                        {getMaxPointages(users) === 0 && (
                          <td
                            key={i}
                            className={`${
                              i % 2 === 0 && 'bg-gray-400'
                            } group-hover:bg-viguierBleu`}
                          />
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="bg-viguierBleu text-white justify-start flex rounded-b pl-2 py-2" />
        </div>
      </div>
    </div>
  );
}
